.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.bp3-table-column-name {
  font-weight: bold;
}

.formula {
  margin: -12px 10px 0 9px;
  white-space: nowrap;
}

.no-edit {
  background-color: #f5f8fa; /* light gray 5 */
}

.footer {
  border-top: 1px solid #738694; /* gray 2 */
  font-weight: bold;
  background-color: #f5f8fa; /* light gray 5 */
}
.footer .summary {
  color: #5c7080; /* text-color-muted */
  font-weight: normal;
}

.start-page {
  margin: auto;
  padding: 20px;
  max-width: 1000px;
}

.big-actions {
  display: flex;
  margin: 0 -20px;
}
.big-actions > * {
  width: 50%;
  margin: 20px;
}

.logo-and-text * {
  display: inline-block;
  vertical-align: middle;
}
.logo-and-text img {
  height: 30px;
  margin-right: 5px;
}

.document-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.spreadtable-outer-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.spreadtable-container {
  flex: 1;
  position: relative;
}
.spreadtable {
  position: absolute;
  left: 0;
  right: 0;
}

body {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.bp3-tree {
  min-width: 200px;
}

.view-editor textarea {
  min-height: 45vh;
}

/* Work around invisible text while editing. See https://github.com/palantir/blueprint/issues/2236 */
.bp3-tree .bp3-editable-text {
  color: black;
}
